import {gql} from '@apollo/client'
import React from 'react'
import Page from '../components/page'
import {StatsPage, TeamStatsSummary} from '../components/stats'

export const GET_STATS = gql`
  query TeamStatsGetStatsQuery($dateRange: DateRange) {
    stats(dateRange: $dateRange) {
      id

      metric
      date
      updatedAt
      value

      provider {
        id
        picture
        name
        roles
      }

      tag {
        id
        name
        color
        level
      }
    }
  }
`

const TeamStatsPage: React.FC = () => (
  <Page title="Team Stats">
    <StatsPage
      query={GET_STATS}
      StatSummary={TeamStatsSummary}
      title="Team Stats"
      emptyError="It looks like your team doesn't have any stats"
    />
  </Page>
)

export default TeamStatsPage
